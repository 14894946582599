<template>
  <div class="order-list-wrapper">
    <en-table-layout :tableData="pageData.data" :loading="loading" :span-method="orderSpanMethod">
      <!-- 小程序商城查询 -->
      <template slot="toolbar" v-if="this.$store.getters.shopInfo.shop_type == 7">
        <el-form-item label="订单状态" class="col-auto">
          <el-select style="width: 100px;" v-model="params2.order_status" size="medium">
            <el-option v-show="item.label !== '待付款'" v-for="item in MixinOrderStatusOptions[0]" :key="item.value"
              :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="订单类型" class="col-auto">
          <el-select style="width: 120px;" v-model="params2.trade_type" size="medium">
            <el-option label="全部" value></el-option>
            <el-option label="计次卡订单" value="3"></el-option>
            <el-option label="宅配卡订单" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto" size="medium">
          <el-input style="width: 270px" v-model.trim="advancedForm.keywords2" placeholder="请输入关键词" clearable
            size="medium">
            <el-select style="width: 130px;" v-model="search_type2" slot="prepend" size="medium">
              <el-option label="卡号所属公司" value="company"></el-option>
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="卡号" value="card_code"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="买家账号" class="col-auto">
          <el-input style="width: 150px" v-model.trim="params2.mobile" placeholder="请输入买家账号" clearable size="medium" />
        </el-form-item>
        <el-form-item class="col-auto" size="medium">
          <el-input style="width: 300px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable
            size="medium">
            <el-select style="width: 135px;" v-model="search_type" slot="prepend" size="medium">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option label="套餐礼包名称" value="shop_combo_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item size="medium">
          <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
        </el-form-item>
        <el-form-item size="medium">
          <el-button @click="exportOrder();" size="small" type="primary">导出订单</el-button>
        </el-form-item>
      </template>
      <!-- 智能套餐卡查询 -->
      <template slot="toolbar" v-if="this.$store.getters.shopInfo.shop_type !== 7">
        <el-form-item label="订单状态" class="col-auto">
          <el-select style="width: 100px;" v-model="params.order_status" size="medium">
            <el-option v-show="item.label !== '待付款'" v-for="item in MixinOrderStatusOptions[0]" :key="item.value"
              :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item class="col-auto" size="medium">
          <el-input style="width: 250px" v-model.trim="advancedForm.keywords" placeholder="请输入关键词" clearable
            size="medium">
            <el-select style="width: 130px;" v-model="search_type" slot="prepend" size="medium">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="买家账号" value="buyer_name"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option label="卡券卡号" value="card_code"></el-option>
              <el-option label="卡型名称" value="card_name"></el-option>
              <el-option label="套餐礼包名称" value="shop_combo_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="卡号所属公司" class="col-auto" size="medium">
          <el-input v-model.trim="params.company" placeholder="请输入卡号所属公司" clearable size="medium" />
        </el-form-item>

        <el-form-item size="medium">
          <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
        </el-form-item>
        <el-form-item size="medium">
          <el-button @click="exportOrder();" size="small" type="primary">导出订单</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="订单编号" prop="trade_sn" width="180" fixed="left">
          <template slot-scope="{row}">
            <span class="ml-2">{{ row.trade_sn }}</span>
          </template>
        </el-table-column>

        <el-table-column label="卡号" prop="card_code" width="180" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.card_code | formatCardNo }}
          </template>
        </el-table-column>
        <el-table-column label="套餐礼包名称" width="180" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.shop_combo_name }}
            <span v-if="scope.row.source_trade_sn && (scope.row.trade_kind === 3 || scope.row.trade_kind === 2)"
              style="color: red;">
              (换新)
            </span>
          </template>
        </el-table-column>

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.image || row.goods_image" style="width: 300px;" alt="" />
              <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt="" />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="goods_name" show-overflow-tooltip width="200">
          <template slot-scope="{row}">
            {{ row.goods_name && row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
          </template>
        </el-table-column>
        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">
            <div v-if="row.goods_type === 'NORMAL'">
              <span>{{ row.shop_price | unitPrice('￥') }}</span>
            </div>
            <div v-else>
              {{ row.revise_exchange_money | unitPrice('￥') }} +
              {{ row.revise_exchange_point }}积分
            </div>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" prop="goods_num" width="80" />

        <el-table-column label="物流状态" width="80">
          <template slot-scope="{row}">
            <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
            <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="240" show-overflow-tooltip>
          <template slot-scope="{row}">
            <template v-if="row.delivery">
              {{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="供货方" width="80">
          <template slot-scope="{row}">
            {{ row.order_source === 0 ? '平台' : (row.order_source === 1 ? '京东' : '自有') }}
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="80">
          <template slot-scope="{row}">
            {{ row.trade_status_text }}
            <div>
              <span style="color: red;">
                {{ row.hangup_status === 1 ? '(店铺挂起)' :
      (row.hangup_status === 2 ? '(企业挂起)' : '') }}
              </span>
            </div>
            <div v-if="row.trade_status_text === '待发货' && row.all_shipped !== null && !row.all_shipped">
              <span style="color: red;">
                (部分发货)
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" prop="shop_combo_name" width="110">
          <template slot-scope="{row}">
            {{ row.trade_form | tradeformFilter }}
          </template>
        </el-table-column>
        <el-table-column label="下单时间" width="160">
          <template slot-scope="{row}">
            {{ row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="用户账号" :prop="this.$store.getters.shopInfo.shop_type == 7 ? 'mobile' : 'member_name'"
          width="150" show-overflow-tooltip class-name="font-family-tttgb" />
        <el-table-column label="用户备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{ row.remark }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="195" fixed="right">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" @click="handleOperateOrder(row)">
              查看详情
            </el-button>
            <el-button
              v-if="!(row.source_trade_sn && (row.trade_kind === 3 || row.trade_kind === 2)) && row.isCancelOrder === 101"
              type="primary" size="small" @click="cancelOrder(row)">
              取消订单
            </el-button>
            <el-button v-if="row.isCancelOrder === 103" disabled type="primary" size="small">
              取消订单
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as API_order from '@/api/order';
import { handleDownload, mergeSkuList,mergesHandleDownload } from '@/utils';
import { CategoryPicker } from '@/components';
import UploadExcelComponent from '@/components/UploadExcel';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import { aiComboCancelOrder } from "@/api/order";
import { Foundation } from "@/../ui-utils";
export default {
  name: 'orderList',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    UploadExcelComponent,
  },
  filters: {
    tradeformFilter (status) {
      const statusMap = {
        1: "普通商品订单",
        2: "礼包订单",
        3: "计次卡订单",
        4: "宅配卡订单"
      };
      return statusMap[status];
    },
  },
  data () {
    return {
      shop_type: this.$store.getters.shopInfo.shop_type,//商城类型
      isCancelBtn: null,
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        order_status: 'ALL',
        ...this.$route.query,
        shop_type: 1,
      },
      params2: {
        page_no: 1,
        page_size: 20,
        buyer_name: '',
        order_status: 'ALL',
        shop_type: 1,
        trade_type: '',
        ...this.$route.query,
      },

      /** 列表分页数据 */
      pageData: { data: [] },
      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: '',
        shop_member_unit_id_two: '',
        order_time_range: [],
      },
      search_type: 'order_sn',
      search_type2: 'company',
      merges: [],//表格导出合并
    };
  },

  mounted () {
    if (this.$route.query.card_code) {
      this.search_type2 = 'card_code'
      this.advancedForm.keywords2 = this.$route.query.card_code
    }
    this.GET_OrderList()
  },
  methods: {
    cancelOrder (row) {
      this.$confirm('确定要取消这个订单？', '提示', { type: 'warning' }).then(() => {
        // return console.log(row.trade_sn,row.card_code,row.shop_combo_id,row)
        let params = {
          trade_sn: row.trade_sn,
          card_code: row.card_code,
          combo_id: row.shop_combo_id,
          reason: "退款原因"
        }
        API_order.aiComboCancelOrder(params).then((res) => {
          if (res.statusCode !== 101) return this.$message.error(res.statusMsg)
          this.$message.success(res.statusMsg)
          this.GET_OrderList();
        });
      })
    },
    orderSpanMethod (
      {
        row,
        column,
        rowIndex,
        columnIndex
      }
    ) {
      if (columnIndex >= 3 && columnIndex <= 9) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      } else {
        return row.span;
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1
      this.params.page_size = size;
      this.params2.page_no = 1
      this.params2.page_size = size;
      this.GET_OrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.params2.page_no = page;
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this[this.$store.getters.shopInfo.shop_type == 7 ? 'params2' : 'params'].page_no = 1
      this.GET_OrderList();
    },

    /** 查看、操作订单 */
    handleOperateOrder (item) {
      let route = this.$route.path.split('/')[2];
      this.$router.push({ path: `/combo-card-shop/order/${route}/detail/${item.trade_sn}` });
    },

    GET_OrderList () {
      this.params.booking_shipment = 0;
      const obj = {};
      let params = {
        ...this[this.$store.getters.shopInfo.shop_type == 7 ? 'params2' : 'params']
      };
      if (this.search_type) {
        obj[this.search_type] = this.advancedForm.keywords;
      }
      if (this.$store.getters.shopInfo.shop_type == 7) {//小程序商城
        if (this.search_type2) {
          console.log(this.advancedForm.keywords2);
          obj[this.search_type2] = this.advancedForm.keywords2;
        }
      }
      params = {
        ...params,
        ...obj,
      };
      delete params.start_time;
      delete params.end_time;
      if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length) {
        params.start_time = this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time = this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      this.loading = true;
      API_order.getAIComboShopList(params).then((res) => {
        this.loading = false;

        this.pageData = res;
        this.pageData.data = mergeSkuList(res.data, 1)
        delete this.params.order_sn;
        delete this.params.buyer_name;
        delete this.params.goods_name;
        delete this.params.ship_no;
        delete this.params.ship_no;
      });
    },
    fomater (status) {
      const statusMap = {
        1: "普通商品订单",
        2: "礼包订单",
        3: "计次卡订单",
        4: "宅配卡订单"
      };
      return statusMap[status];
    },
    /** 导出订单 */
    exportOrder () {
      this.merges=[];
      const header = [
        "订单号",
        "卡号",
        "套餐礼包名称",
        "商品名称以及规格",
        "商品单价",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "订单状态",
        "订单类型",
        "物流公司",
        "物流单号",
        this.$store.getters.shopInfo.shop_type == 7 ? "用户账号" : "买家账号",
        "用户备注信息",
      ]
      const values = [
        "order_sn",
        "card_code",
        "shop_combo_name",
        "name",
        "supplier_purchase_price",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_status_text",
        "trade_form",//订单类型
        "logi_name",
        //'ship_status_text',
        "ship_no",
        this.$store.getters.shopInfo.shop_type == 7 ? "mobile" : "member_name",
        "remark",
      ]
      this.params.booking_shipment = 0;
      const obj = {};
      let params = {
        ...this[this.$store.getters.shopInfo.shop_type == 7 ? 'params2' : 'params']
      };
      if (this.search_type) {
        obj[this.search_type] = this.advancedForm.keywords;
      }
      if (this.$store.getters.shopInfo.shop_type == 7) {//小程序商城
        if (this.search_type2) {
          console.log(this.advancedForm.keywords2);
          obj[this.search_type2] = this.advancedForm.keywords2;
        }
      }
      params = {
        ...params,
        ...obj,
      };
      delete params.start_time;
      delete params.end_time;
      if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length) {
        params.start_time = this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time = this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      params.page_no = 0;
      params.page_size = 0;

      API_order.getAIComboShopList(params).then((res) => {
        this.loading = false;
        let data = mergeSkuList(res.data, 1);
        const exportData = data.map(item => {
          return {
            card_code: Foundation.formatCardNo(item.card_code),
            shop_combo_name: item.shop_combo_name,
            name: item?.goods_name?.replaceAll('amp;', '')?.replaceAll(' ', '&nbsp;'),
            sku_sn: item.sku_sn,
            num: item.goods_num,
            ship_addr:
              item.consignee_province +
              item.consignee_city +
              item.consignee_county +
              item.consignee_town +
              item.consignee_address,
            ship_name: item.consignee_name,
            ship_mobile: item.consignee_mobile,
            order_sn: item.trade_sn,
            order_status_text: item.trade_status_text,
            trade_form: this.fomater(item.trade_form),//订单类型
            logi_name: item.delivery?.logiName ?? '',
            ship_no: item.delivery?.deliveryNo ?? '',
            member_name: item.member_name,
            remark: item.remark,
            supplier_purchase_price: item.shop_price,
            mobile: item.mobile
          }
        })
        //需要合并的单元格
        data.forEach((m, index) => {
          let uum = 0;
          let columnCount=17;
          this.parentLogin === 1 ? uum = 0 : uum = 1 //是否显示会员价
          if (this.isSpecificEnterprise) {
            columnCount+=1;
          }
          if (m.span.rowspan > 1) {
            for (let i = 0; i < columnCount - uum; i++) {
              if (i < 1 || i > 10 - uum) {
                this.merges.push(
                  { s: { r: index + 1, c: i }, e: { r: index + m.span.rowspan, c: i } }
                )
              }
            }
          }
        })
        mergesHandleDownload(exportData, header, values, "智能套餐卡订单列表", true, this.merges);
        // handleDownload(exportData, header, values, "智能套餐卡订单列表");
      });
    },
  },
};

</script>

<style lang="scss" scoped>
/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .en-table-layout .en-table-layout-toolbar {
  padding-bottom: 0;
}
</style>
